<template>
  <section class="all-templates-view">
    <el-row type="flex" justify="center" align="center">
      <el-col :span="22">
        <div style="margin-bottom: 20px" class="top-board">
          <div class="title">
            <el-tooltip
              content="Go Back to All Workflows"
              v-if="!this.$route.query.routeType"
            >
              <el-button @click="goBack" style="height: 30px">
                <i class="el-icon-back"></i>
                {{ getIsMobile ? "" : "Back" }}
              </el-button>
            </el-tooltip>
            <h3
              v-if="this.selectedRoute == 'IN-PROGRESS'"
              class="title fw-normal text-dark mb-0 ls-05 ml-2"
            >
              In Progress Data {{ this.workflowName }}
            </h3>
            <h3
              v-else-if="this.selectedRoute == 'COMPLETED'"
              class="title fw-normal text-dark mb-0 ls-05 ml-2"
            >
              Completed Workflow Data
            </h3>
            <h3
              v-else-if="this.selectedRoute == 'TOTAL-STATUS'"
              class="title fw-normal text-dark mb-0 ls-05 ml-2"
            >
              All Workflow Data
            </h3>
            <h3
              v-else-if="this.selectedRoute == 'DRAFT'"
              class="title fw-normal text-dark mb-0 ls-05 ml-2"
            >
              Draft Workflow Data
            </h3>
            <h3
              v-else-if="this.selectedRoute == 'SENT'"
              class="title fw-normal text-dark mb-0 ls-05 ml-2"
            >
              Sent Workflow Data
            </h3>
          </div>
          <div class="actions-wrapper">
            <el-button
              class="mr-1"
              v-if="
                this.selected_workflow_instance &&
                this.selected_workflow_instance.length &&
                this.selected_workflow_instance.length >= 1 &&
                this.$route &&
                this.$route.query &&
                this.$route.query.type &&
                this.$route.query.type === 'DRAFT'
              "
              @click="permanentDeleteWorkflowInstancesInDraft()"
              title="Delete"
            >
              <i class="el-icon-delete"></i>
            </el-button>
            <el-input
              class="search"
              placeholder="Search"
              v-model="search_string"
              clearable
              :style="getIsMobile ? '' : 'width:170px;'"
            >
            </el-input>
            <el-select
              v-if="
                this.$route &&
                this.$route.query.type != 'IN-PROGRESS' &&
                this.$route.query.type != 'COMPLETED'
              "
              v-model="selectedRoute"
              filterable
              clearable
              class="select"
              placeholder="Select Status"
              @change="loadData"
              :style="
                getIsMobile ? 'width:170px' : 'width:170px;margin-left:11px'
              "
            >
              <el-option
                v-for="(status, index) in statusOption"
                :key="index"
                :value="status.value"
                :label="status.name"
              ></el-option>
            </el-select>
            <el-tooltip placement="top" content="Send workflow to receiver">
              <el-button
                v-if="
                  this.$route &&
                  this.$route.query.type != 'IN-PROGRESS' &&
                  this.$route.query.type != 'COMPLETED'
                "
                plain
                :style="getIsMobile ? '' : 'width:170px;'"
                class="create-btn px-4 ml-2"
                type="primary"
                @click="onSend()"
                >Send Workflow</el-button
              >
            </el-tooltip>
          </div>
        </div>
        <div
          v-loading="configureLoading ? configureLoading : loadingWorkflow"
          :element-loading-text="loadingText"
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(0, 0, 0, 0.8)"
          class="vue-data-table-default"
        >
          <data-tables-server
            :fullscreen="true"
            :total="total"
            :data="allWorkflows"
            :current-page.sync="currentPage"
            :page-size="pageSize"
            :pagination-props="paginationProps"
            style="width: 100%"
            @selection-change="handleSelectionChange"
            @query-change="loadData"
            class="workflows-table"
          >
            <el-table-column
              type="selection"
              width="55"
              v-if="this.$route && this.$route.name != 'Dashboard'"
            >
            </el-table-column>
            <el-table-column
              label="Primary User"
              fixed="left"
              :width="getIsMobile ? 120 : 200"
            >
              <template slot-scope="scope">
                <p>{{ getPrimaryUserFirstNameAndLastName(scope.row) }}</p>
              </template>
            </el-table-column>

            <!-- <el-table-column
              label="Name"
              id="name"
              prop="title"
              :width="getIsMobile ? 130 : 250"
              fixed="left"
              sortable="custom"
              ><template slot-scope="scope">
                <el-popover
                  placement="top-start"
                  width="200"
                  trigger="hover"
                  :content="scope.row.name"
                >
                  <a
                    slot="reference"
                    class="doc-title"
                    @click="sendWorkflow(scope.row, 0)"
                  >
                    {{ scope.row.name | truncate(18, "...") }}
                  </a>
                </el-popover>
              </template>
              </el-table-column> -->

            <el-table-column
              label="Actions"
              prop="action"
              class="action"
              :width="getIsMobile ? 150 : 200"
            >
              <template slot-scope="scope">
                <ul class="action-buttons hover-buttons">
                  <el-tooltip
                    content="View Workflow"
                    v-if="scope.row.workflow_status != 'DRAFT'"
                  >
                    <li>
                      <el-button
                        plain
                        type="danger"
                        size="mini"
                        class="px-2"
                        @click="openWorkflow(scope.row)"
                      >
                        <i class="el-icon-view"></i>
                      </el-button>
                    </li>
                  </el-tooltip>
                  <el-tooltip content="Send Workflow" v-else>
                    <li>
                      <el-button
                        plain
                        type="danger"
                        size="mini"
                        class="px-2"
                        @click="sendWorkflow(scope.row, 1)"
                      >
                        <i class="el-icon-position"></i>
                      </el-button>
                    </li>
                  </el-tooltip>
                  <li>
                    <el-dropdown>
                      <el-button size="mini" class="el-dropdown-link px-2">
                        Actions
                        <i class="el-icon-arrow-down el-icon--right"></i>
                      </el-button>
                      <el-dropdown-menu slot="dropdown">
                        <template
                          v-if="
                            scope.row.created_by == authid ||
                            (getUserType &&
                              getActiveWorkspace &&
                              getActiveWorkspace.company_id)
                          "
                        >
                          <a
                            @click="onDeleteConfirmation(scope.row)"
                            v-if="
                              scope.row.workflow_status != 'ARCHIVED' &&
                              scope.row.workflow_status != 'DRAFT' &&
                              (scope.row.created_by == authid ||
                                (scope.row.company_id &&
                                  getActiveWorkspace &&
                                  getActiveWorkspace.company_id &&
                                  scope.row.company_id ==
                                    getActiveWorkspace.company_id))
                            "
                          >
                            <el-dropdown-item>
                              <i class="el-icon-delete"></i>
                              Delete</el-dropdown-item
                            >
                          </a>
                          <a
                            v-if="
                              scope.row.status == 'ACTIVE' &&
                              scope.row.workflow_status == 'SENT' &&
                              (scope.row.user_id == authid ||
                                (scope.row.company_id &&
                                  getActiveWorkspace &&
                                  getActiveWorkspace.company_id &&
                                  scope.row.company_id ==
                                    getActiveWorkspace.company_id))
                            "
                            @click="resendWorkflowToPrimaryUser(scope.row)"
                          >
                            <el-dropdown-item>
                              <i class="el-icon-finished"></i>
                              Resend</el-dropdown-item
                            >
                          </a>
                          <a
                            @click="onpermanentDocument(scope.row)"
                            v-if="
                              scope.row.workflow_status == 'DRAFT' &&
                              (scope.row.created_by == authid ||
                                (scope.row.company_id &&
                                  getActiveWorkspace &&
                                  getActiveWorkspace.company_id &&
                                  scope.row.company_id ==
                                    getActiveWorkspace.company_id))
                            "
                          >
                            <el-dropdown-item
                              ><i class="el-icon-delete-solid"></i> Permanent
                              Delete</el-dropdown-item
                            >
                          </a>
                        </template>
                      </el-dropdown-menu>
                    </el-dropdown>
                  </li>
                </ul>
              </template>
            </el-table-column>

            <el-table-column
              prop="document_tracking_status.description"
              label="Current Status"
              :width="getIsMobile ? 150 : 250"
            >
              <template slot-scope="scope">
                <el-progress
                  :percentage="getWorkflowStatus(scope.row)"
                  :format="format"
                  :width="10"
                  v-if="scope.row.workflow_status != 'DRAFT'"
                >
                </el-progress>
                <p
                  style="font-size: 10px"
                  class="doc-subs"
                  v-if="scope.row.workflow_status != 'DRAFT'"
                >
                  {{ getFormat(scope.row) }}
                </p>
                <p
                  style="font-size: 14px; word-break: keep-all"
                  v-if="scope.row.workflow_status == 'DRAFT'"
                >
                  {{ getCurrentDraftStatus(scope.row) }}
                </p>
              </template>
            </el-table-column>
            <el-table-column
              label="Status"
              id="status"
              prop="title"
              sortable="custom"
              :width="getIsMobile ? 100 : 250"
            >
              <template slot-scope="scope" id="category">
                <div
                  class="d-flex activestatus"
                  v-if="scope.row.workflow_status"
                >
                  <div
                    :class="scope.row.workflow_status.toLowerCase() + ' circle'"
                  ></div>
                  <div>
                    <p>{{ scope.row.workflow_status }}</p>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              label="Last Modified"
              prop="last_modified"
              sortable="custom"
              :width="getIsMobile ? 150 : 250"
              ><template slot-scope="scope">{{
                scope.row.updated_at | globalDateTimeFormat
              }}</template></el-table-column
            >
          </data-tables-server>
        </div>
      </el-col>
    </el-row>
    <el-dialog
      :visible.sync="documentModal"
      title="Documents"
      :width="getIsMobile ? '100%' : '75%'"
      v-loading="loading"
      :element-loading-text="loadingText"
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.8)"
      style="margin-left: 60px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <div>
        <el-scrollbar wrap-style="max-height: 400px;">
          <el-table :data="selectedDocuments" style="width: 100%" border>
            <el-table-column min-width="100" label="Title">
              <template
                slot-scope="scope"
                v-if="scope.row.document_id && scope.row.document_id != 'null'"
              >
                <el-popover
                  placement="top-start"
                  width="200"
                  trigger="hover"
                  :content="scope.row.name"
                >
                  <a slot="reference" class="doc-title">
                    {{ scope.row.name | truncate(15, "...") }}
                  </a>
                </el-popover>
              </template>
            </el-table-column>
            <el-table-column
              prop="name"
              label="Document Receivers"
              min-width="180"
            >
              <template
                slot-scope="scope"
                v-if="scope.row.document_id && scope.row.document_id != 'null'"
              >
                <div
                  v-for="(user, index) in scope.row.document_id.document_users"
                  :key="index"
                >
                  <p
                    v-if="
                      !user.is_cc &&
                      user.type != 'SENDER' &&
                      user &&
                      user.first_name
                    "
                  >
                    {{ user.first_name + " " + user.last_name }}
                    <el-tooltip
                      content="You can communicate with recipients by sending an email"
                    >
                      <a
                        style="font-family: inter"
                        v-if="!user.is_cc && user.type != 'SENDER'"
                        :href="`mailto: ${user.email}`"
                        >Send Email</a
                      >
                    </el-tooltip>
                  </p>
                </div>
              </template>
              <!---->
            </el-table-column>
            <el-table-column
              prop="document_tracking_status"
              label="Tracking status"
              min-width="200"
            >
              <template
                slot-scope="scope"
                v-if="
                  scope.row.document_id &&
                  scope.row.document_id != 'null' &&
                  scope.row.document_id.document_tracking_status &&
                  scope.row.document_id.document_tracking_status.type
                "
              >
                <p
                  slot="reference"
                  style="font-family: inter; font-size: 14px"
                  class="text-secondary-sent"
                >
                  {{
                    scope.row.document_id.document_tracking_status.description
                      | truncate(38, "...")
                  }}
                </p>
              </template>
            </el-table-column>
            <el-table-column
              prop="document_status"
              label="Document Status"
              min-width="100"
            >
              <template
                slot-scope="scope"
                v-if="
                  scope.row.document_id &&
                  scope.row.document_id != 'null' &&
                  scope.row.document_id.document_status
                "
              >
                <p
                  slot="reference"
                  style="font-family: inter; font-size: 14px"
                  class="text-secondary-sent"
                >
                  {{ scope.row.document_id.document_status }}
                </p>
              </template>
            </el-table-column>

            <el-table-column label="Actions" width="190">
              <template
                slot-scope="scope"
                v-if="scope.row.document_id && scope.row.document_id != 'null'"
              >
                <div style="display: flex">
                  <el-button
                    style="background-color: #f754a2; color: #ffffff"
                    size="mini"
                    @click="onView(scope.row.document_id)"
                  >
                    View
                  </el-button>
                  <el-button
                    size="mini"
                    v-if="
                      ((scope.row.document_id &&
                        scope.row.document_id != 'null' &&
                        scope.row.document_id.document_status == 'SENT') ||
                        scope.row.document_id.document_status == 'APPROVER') &&
                      !scope.row.document_id.document_filling_status &&
                      checkToOnlyResend(scope.row.document_id)
                    "
                    @click="resendDocLink(scope.row.document_id)"
                  >
                    Resend
                  </el-button>
                  <el-button
                    size="mini"
                    v-else-if="
                      scope.row.document_id.document_status == 'COMPLETED'
                    "
                    @click="onDownload(scope.row, scope.row.document_id)"
                  >
                    <el-tooltip content="Download Document"
                      ><i
                        size="mini"
                        style="color: #f754a2; width: -5px"
                        class="el-icon-download"
                      ></i>
                    </el-tooltip>
                  </el-button>
                  <el-dropdown>
                    <img
                      style="margin-top: 4px; margin-left: 5px"
                      src="@/assets/img/icons/more-icon.svg"
                      alt="icon"
                      class="el-dropdown-link"
                    />
                    <el-dropdown-menu slot="dropdown">
                      <a
                        v-if="
                          scope.row.document_id &&
                          scope.row.document_id != 'null'
                        "
                        @click="getAllDocLogs(scope.row.document_id)"
                      >
                        <el-dropdown-item
                          ><i class="el-icon-refresh-right"></i>
                          History</el-dropdown-item
                        >
                      </a>
                      <a
                        @click="alertCorrectedDoc(scope.row.document_id)"
                        v-if="
                          ((scope.row.created_by == authid ||
                            checkPermissionForDocument(
                              scope.row.document_id,
                              'correctDocument'
                            )) &&
                            scope.row.document_id &&
                            scope.row.document_id != 'null' &&
                            scope.row.document_id.document_status != 'VOIDED' &&
                            scope.row.document_id.document_status !=
                              'COMPLETED' &&
                            scope.row.document_id.document_status !=
                              'ARCHIVED' &&
                            scope.row.document_id.document_status !=
                              'EXPIRED') ||
                          isfilledOrNot(scope.row.document_id)
                        "
                      >
                        <el-dropdown-item
                          ><i class="el-icon-document-checked"></i> Correct the
                          document</el-dropdown-item
                        >
                      </a>
                      <a
                        @click="alertvoided(scope.row.document_id)"
                        v-if="
                          checkPermissionForDocument(
                            scope.row.document_id,
                            'voidDocument'
                          ) &&
                          scope.row &&
                          scope.row.document_id.document_status &&
                          scope.row.document_id.document_status != 'VOIDED' &&
                          scope.row.document_id.document_status != 'DECLINED' &&
                          scope.row.document_id.document_status !=
                            'COMPLETED' &&
                          scope.row.document_id.document_status != 'EXPIRED' &&
                          scope.row.document_id.document_status != 'ARCHIVED'
                        "
                      >
                        <el-dropdown-item>
                          <i class="el-icon-remove-outline"></i>Void Document
                        </el-dropdown-item>
                      </a>
                      <a
                        @click="EditVoidDocument(scope.row.document_id._id)"
                        v-if="
                          checkPermissionForDocument(
                            scope.row.document_id,
                            'cloneDocument'
                          ) &&
                          scope.row.document_id &&
                          scope.row.document_id != 'null' &&
                          (scope.row.document_id.document_status == 'VOIDED' ||
                            scope.row.document_id.document_status ==
                              'COMPLETED')
                        "
                      >
                        <el-dropdown-item
                          ><i class="el-icon-document-copy"></i>
                          Clone</el-dropdown-item
                        >
                      </a>
                      <a
                        v-if="
                          checkPermissionForDocument(
                            scope.row.document_id,
                            'deleteDocument'
                          ) &&
                          scope.row.document_id &&
                          scope.row.document_id != 'null' &&
                          scope.row.document_id.document_status &&
                          scope.row.document_id.document_status != 'ARCHIVED'
                        "
                        @click="archiveDocument(scope.row.document_id)"
                      >
                        <el-dropdown-item
                          ><i class="el-icon-delete"></i
                          >Delete</el-dropdown-item
                        >
                      </a>
                      <a
                        @click="permanentDelete(scope.row.document_id)"
                        v-if="
                          scope.row.document_id &&
                          scope.row.document_id != 'null' &&
                          scope.row.document_id.document_status &&
                          scope.row.document_id.document_status == 'ARCHIVED'
                        "
                      >
                        <el-dropdown-item
                          ><i class="el-icon-delete-solid"></i>Permanent
                          Delete</el-dropdown-item
                        >
                      </a>
                      <a
                        v-if="
                          scope.row &&
                          scope.row.document_id.document_status &&
                          scope.row.document_id.document_status != 'VOIDED' &&
                          scope.row.document_id.document_status != 'DECLINED' &&
                          scope.row.document_id.document_status !=
                            'COMPLETED' &&
                          scope.row.document_id.document_status != 'EXPIRED'
                        "
                      >
                        <el-dropdown-item disabled>
                          <i class="el-icon-timer"></i>Expires On
                          {{
                            expiray_date(scope.row.document_id)
                              | globalDateFormat
                          }}
                        </el-dropdown-item>
                      </a>
                    </el-dropdown-menu>
                  </el-dropdown>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </el-scrollbar>
      </div>
      <span slot="footer">
        <el-button
          type="primary"
          v-if="
            this.selectedDocuments &&
            this.selectedDocuments.length &&
            this.selectedRoute == 'IN-PROGRESS'
          "
          @click="onDeleteWorkflowConfirmation"
          >Delete Workflow</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      title="Document History"
      :destroy-on-close="true"
      :visible.sync="viewDetailedHistory"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :lock-scroll="false"
      :width="getIsMobile ? '100%' : '55%'"
      :before-close="resetHistory"
    >
      <el-scrollbar wrap-style="max-height: 600px;padding:13px;">
        <div style="height: 430px">
          <vue-html2pdf
            :show-layout="true"
            :float-layout="false"
            :enable-download="true"
            :preview-modal="false"
            filename="hee hee"
            :pagebreak="{
              mode: 'css',
            }"
            :pdf-quality="10"
            :manual-pagination="true"
            pdf-format="a4"
            pdf-orientation="portrait"
            :html-to-pdf-options="{
              html2canvas: { useCORS: true },
            }"
            ref="html2Pdf"
          >
            <section slot="pdf-content">
              <section id="details-report">
                <div style="padding: 10px">
                  <h3 class="float-left fw-bold fs-22">Document Details</h3>
                  <div class="d-flex">
                    <el-row>
                      <el-col
                        :xl="{ span: 8 }"
                        :lg="{ span: 8 }"
                        :md="{ span: 8 }"
                        :sm="{ span: 8 }"
                        :xs="{ span: 8 }"
                      >
                        <span class="details">Document Name</span>
                        <p class="table">
                          {{
                            this.selectedHistroyDocuments.Docname
                              | truncate(20, "...")
                          }}
                        </p>
                      </el-col>
                      <el-col
                        :xl="{ span: 8 }"
                        :lg="{ span: 8 }"
                        :md="{ span: 8 }"
                        :sm="{ span: 8 }"
                        :xs="{ span: 8 }"
                      >
                        <span class="details">Date Created</span>
                        <p class="table">
                          {{
                            this.selectedHistroyDocuments.created_at
                              | globalDateTimeFormat
                          }}
                        </p>
                      </el-col>
                      <el-col
                        :xl="{ span: 8 }"
                        :lg="{ span: 8 }"
                        :md="{ span: 8 }"
                        :sm="{ span: 8 }"
                        :xs="{ span: 8 }"
                      >
                        <span class="details">Sender</span>
                        <p class="table">
                          {{ this.selectedHistroyDocuments.senderName }}
                        </p>
                      </el-col>
                      <el-col
                        :xl="{ span: 8 }"
                        :lg="{ span: 8 }"
                        :md="{ span: 8 }"
                        :sm="{ span: 8 }"
                        :xs="{ span: 8 }"
                      >
                        <span class="details">Document Status</span>
                        <p
                          class="table"
                          v-if="
                            this.selectedHistroyDocuments.Document_Status ==
                            'SENT'
                          "
                        >
                          Sent
                        </p>
                        <p
                          class="table"
                          v-if="
                            this.selectedHistroyDocuments.Document_Status ==
                            'COMPLETED'
                          "
                        >
                          Completed
                        </p>
                        <p
                          class="table"
                          v-if="
                            this.selectedHistroyDocuments.Document_Status ==
                            'VOIDED'
                          "
                        >
                          Voided
                        </p>
                        <p
                          class="table"
                          v-if="
                            this.selectedHistroyDocuments.Document_Status ==
                            'DECLINED'
                          "
                        >
                          Declined
                        </p>
                        <p
                          class="table"
                          v-if="
                            this.selectedHistroyDocuments.Document_Status ==
                            'ARCHIVED'
                          "
                        >
                          Archived
                        </p>
                      </el-col>

                      <el-col
                        :xl="{ span: 8 }"
                        :lg="{ span: 8 }"
                        :md="{ span: 8 }"
                        :sm="{ span: 8 }"
                        :xs="{ span: 8 }"
                      >
                        <span class="details">Document ID</span>
                        <p
                          v-if="
                            this.selectedHistroyDocuments.Document_Status ==
                            'COMPLETED'
                          "
                        >
                          {{
                            this.selectedHistroyDocuments.documentIdforHistory
                          }}
                        </p>
                        <p v-else>-- --</p>
                      </el-col>

                      <el-col
                        :xl="{ span: 8 }"
                        :lg="{ span: 8 }"
                        :md="{ span: 8 }"
                        :sm="{ span: 8 }"
                        :xs="{ span: 8 }"
                      >
                        <span class="details">
                          Date Format
                          <p
                            style="
                              font-family: inter;
                              font-size: 12px;
                              font-weight: normal;
                            "
                          >
                            {{ dateFormat }}
                          </p>
                        </span>
                      </el-col>

                      <el-col
                        :xl="{ span: 24 }"
                        :lg="{ span: 24 }"
                        :md="{ span: 24 }"
                        :sm="{ span: 24 }"
                        :xs="{ span: 24 }"
                      >
                        <span class="details">Document Recipients</span>
                        <div class="d-flex">
                          <div
                            v-for="(el, index) in this.selectedHistroyDocuments
                              .Doc_recipients"
                            :key="index"
                          >
                            <p class="table" v-if="el && el.type != 'SENDER'">
                              {{ el.first_name + " " + el.last_name + "," }}
                            </p>
                          </div>
                        </div>
                      </el-col>
                    </el-row>
                  </div>
                  <el-divider></el-divider>
                  <h3>Activities</h3>
                </div>
                <el-table
                  style="width: 100%"
                  border
                  :data="documentLogs"
                  stripe
                >
                  <el-table-column label="Document Users" min-width="110">
                    <template
                      slot-scope="scope"
                      v-if="scope.row.user_id || scope.row.contact_id"
                    >
                      <p v-if="scope.row.user_id" class="table">
                        {{
                          scope.row.user_id.first_name +
                          " " +
                          scope.row.user_id.last_name
                        }}
                      </p>
                      <p v-if="scope.row.contact_id" class="table">
                        {{
                          scope.row.contact_id.first_name +
                          " " +
                          scope.row.contact_id.last_name
                        }}
                      </p>
                    </template>
                  </el-table-column>
                  <el-table-column label="Status" min-width="95">
                    <template
                      slot-scope="scope"
                      v-if="scope.row.user_id || scope.row.contact_id"
                    >
                      <p
                        class="table"
                        v-if="scope.row.status == 'DOCUMENT_VIEWED'"
                      >
                        Viewed
                      </p>
                      <p
                        class="table"
                        v-if="scope.row.status == 'DOCUMENT_SETTINGS_CHANGED'"
                      >
                        Settings Changed
                      </p>
                      <p
                        class="table"
                        v-if="scope.row.status == 'DOCUMENT_SIGNED'"
                      >
                        Signed
                      </p>
                      <p
                        class="table"
                        v-if="scope.row.status == 'DOCUMENT_SENT'"
                      >
                        Sent
                      </p>
                      <p
                        class="table"
                        v-if="scope.row.status == 'DOCUMENT_DECLINED'"
                      >
                        Declined
                      </p>
                      <p
                        class="table"
                        v-if="scope.row.status == 'ACCEPTED_TERMS_&_CONDITIONS'"
                      >
                        Accepted <br />Terms & Conditions
                      </p>
                      <p
                        class="table"
                        v-if="scope.row.status == 'DOCUMENT_VOIDED'"
                      >
                        Voided
                      </p>
                      <p
                        class="table"
                        v-if="scope.row.status == 'DOCUMENT_CORRECTED'"
                      >
                        Corrected
                      </p>
                      <p
                        class="table"
                        v-if="scope.row.status == 'DOCUMENT_APPROVED'"
                      >
                        Approved
                      </p>
                      <p
                        class="table"
                        v-if="scope.row.status == 'Document Deleted'"
                      >
                        Deleted
                      </p>
                      <p
                        class="table"
                        v-if="scope.row.status == 'Undo Deleted Document'"
                      >
                        Undo Document
                      </p>
                    </template>
                  </el-table-column>
                  <el-table-column label="Document Activity" min-width="200">
                    <template
                      slot-scope="scope"
                      v-if="scope.row.user_id || scope.row.contact_id"
                    >
                      <p class="table">{{ scope.row.description }}</p>
                    </template>
                  </el-table-column>
                  <el-table-column label="Date & Time" min-width="110">
                    <template
                      slot-scope="scope"
                      v-if="scope.row.user_id || scope.row.contact_id"
                    >
                      <p class="table">
                        {{ scope.row.created_at | globalDateTimeFormat }}
                      </p>
                    </template>
                  </el-table-column>
                </el-table>
              </section>
            </section>
          </vue-html2pdf>
        </div>
      </el-scrollbar>
      <div
        v-if="selectedHistroyDocuments.Document_Status === 'COMPLETED'"
        style="display: none"
      >
        <vue-html2pdf
          :show-layout="true"
          :float-layout="false"
          :enable-download="true"
          :preview-modal="false"
          filename="hee hee"
          :pagebreak="{
            mode: 'css',
          }"
          :pdf-quality="10"
          :manual-pagination="true"
          pdf-format="a4"
          pdf-orientation="portrait"
          :html-to-pdf-options="{
            html2canvas: { useCORS: true },
          }"
          ref="doc2Cer"
        >
          <section slot="pdf-content">
            <section id="certificate-report">
              <div style="padding: 10px">
                <img
                  src="@/assets/img/eSigns Logo Black.svg"
                  alt="Nimble logo"
                />
                <div style="background: #f2f6fc; padding: 5px">
                  <p style="font-size: 16px" class="fw-bold fs-22">
                    Document Details
                  </p>
                </div>
                <div class="mt-1">
                  <el-row>
                    <el-col :span="12">
                      <div class="d-flex">
                        ID:
                        <p class="fw-bold ml-1">{{ documentCompletedID }}</p>
                      </div>
                    </el-col>
                    <el-col :span="12">
                      <div class="d-flex">
                        Signatures:
                        <p class="fw-bold ml-1">{{ getSignaturesCount }}</p>
                      </div>
                    </el-col>
                  </el-row>
                  <el-row class="mt-1">
                    <el-col :span="12">
                      <div class="d-flex">
                        Subject:
                        <p class="fw-bold ml-1">{{ getDocumentSubject }}</p>
                      </div>
                    </el-col>
                    <el-col :span="12">
                      <div class="d-flex">
                        Initials:
                        <p class="fw-bold ml-1">{{ getInitialsCount }}</p>
                      </div>
                    </el-col>
                  </el-row>
                  <el-row class="mt-1">
                    <el-col :span="12">
                      <div class="d-flex">
                        Document Pages:
                        <p class="fw-bold ml-1">{{ pdfPages }}</p>
                      </div>
                    </el-col>
                    <el-col :span="12">
                      <div class="d-flex">
                        Status:
                        <p class="fw-bold ml-1">COMPLETED</p>
                      </div>
                    </el-col>
                  </el-row>
                  <el-row class="mt-1">
                    <el-col :span="12">
                      <div class="d-flex">
                        Certificate pages:
                        <p class="fw-bold ml-1">1</p>
                      </div>
                    </el-col>
                    <el-col :span="12">
                      <div class="d-flex">
                        Sender:
                        <p class="fw-bold ml-1">{{ getSenderEmail }}</p>
                      </div>
                    </el-col>
                  </el-row>
                  <el-row class="mt-1">
                    <el-col :span="12">
                      <div class="d-flex">
                        Time Zone:
                        <p class="fw-bold ml-1">{{ getDocumentCreatedZone }}</p>
                      </div>
                    </el-col>
                    <el-col :span="12">
                      <div class="d-flex">
                        Ip address:
                        <p class="fw-bold">{{ getSenderIpAddress }}</p>
                      </div>
                    </el-col>
                  </el-row>
                  <div style="background: #f2f6fc; padding: 5px" class="mt-1">
                    <p style="font-size: 16px" class="fw-bold fs-22">
                      Document Tracking
                    </p>
                  </div>
                  <el-row class="mt-1">
                    <el-col :span="12">
                      <div class="d-flex">
                        Document Created:
                        <p class="fw-bold">
                          {{ getDocumentCreatedAt | globalDateTimeFormat }}
                        </p>
                      </div>
                    </el-col>
                    <el-col :span="12">
                      <div class="d-flex">
                        Document Completed:
                        <p class="fw-bold">
                          {{ getDocumentCompletedAt | globalDateTimeFormat }}
                        </p>
                      </div>
                    </el-col>
                  </el-row>
                  <el-row
                    style="background: #f2f6fc; padding: 5px"
                    class="mt-1"
                  >
                    <el-col :span="8">
                      <p style="font-size: 16px" class="fw-bold fs-22">
                        Signer Details
                      </p>
                    </el-col>
                    <el-col :span="8">
                      <p style="font-size: 16px" class="fw-bold fs-22">
                        Signature
                      </p>
                    </el-col>
                    <el-col :span="8">
                      <p style="font-size: 16px" class="fw-bold fs-22">
                        Timeframe
                      </p>
                    </el-col>
                  </el-row>
                  <el-row
                    v-for="(el, i) in getUsersForCertificate"
                    :key="i"
                    style="border-bottom: 1px solid #e1e1e1; padding: 10px"
                  >
                    <el-col :span="8" style="font-size: 12px">
                      <div class="d-flex">
                        Name:
                        <p class="fw-bold fs-22 ml-1">{{ el.name }}</p>
                      </div>
                      <div class="d-flex">
                        Email:
                        <p class="fw-bold fs-22 ml-1">{{ el.email }}</p>
                      </div>
                    </el-col>
                    <el-col :span="8" style="font-size: 12px">
                      <div v-if="el.signature_source && !el.print_complete">
                        <img :src="el.signature_source" style="width: 200px" />
                      </div>
                      <div v-if="el.print_complete" style="color: #409eff">
                        <h4>Uploaded paper with hand signature</h4>
                      </div>
                      <div class="d-flex">
                        Signature Adoption:
                        <p class="fw-bold fs-22">
                          {{
                            el.print_complete
                              ? "Signed on Paper"
                              : "Drawn on Device"
                          }}
                        </p>
                      </div>
                      <div class="d-flex">
                        Using IP Address:
                        <p class="fw-bold fs-22 ml-1">{{ el.user_ip }}</p>
                      </div>
                    </el-col>
                    <el-col :span="8" style="font-size: 12px">
                      <div class="d-flex">
                        Sent:
                        <p class="fw-bold fs-22" v-if="el.sent_time">
                          {{ el.sent_time | globalDateTimeFormat }}
                        </p>
                      </div>
                      <div class="d-flex">
                        Terms & Conditions:
                        <p class="fw-bold fs-22" v-if="el.accept_time">
                          {{ el.accept_time | globalDateTimeFormat }}
                        </p>
                      </div>
                      <div class="d-flex">
                        Viewed:
                        <p class="fw-bold fs-22" v-if="el.view_time">
                          {{ el.view_time | globalDateTimeFormat }}
                        </p>
                      </div>
                      <div class="d-flex">
                        Signed:
                        <p class="fw-bold fs-22" v-if="el.completed_time">
                          {{ el.completed_time | globalDateTimeFormat }}
                        </p>
                      </div>
                    </el-col>
                  </el-row>
                </div>
              </div>
            </section>
          </section>
        </vue-html2pdf>
      </div>
      <span slot="footer">
        <el-button
          v-if="selectedHistroyDocuments.Document_Status === 'COMPLETED'"
          @click="generateCertificate"
          >Download Certificate</el-button
        >
        <el-button
          type="primary"
          @click="generateReport"
          :loading="detailsDownload"
          >Download History</el-button
        >
      </span>
    </el-dialog>
  </section>
</template>
<script>
import { mapGetters } from "vuex";
import appConfig from "@/config/app";
import axios from "@/config/axios";
import VueHtml2pdf from "vue-html2pdf";
import PermissionsHelper from "@/mixins/permissionsHelper";
import ComanyDocumentsPresignedURLsHelper from "@/mixins/ComanyDocumentsPresignedURLsHelper";

import NavigationHelper from "@/mixins/navigationHelper";
import {
  successNotification,
  errorNotification,
} from "@/helpers/notifications.js";
export default {
  name: "workflows-templateWorkflowDataList",
  components: {
    VueHtml2pdf,
  },
  mixins: [
    PermissionsHelper,
    NavigationHelper,
    ComanyDocumentsPresignedURLsHelper,
  ],
  data() {
    return {
      search_string: "",
      configureLoading: false,
      loading: false,
      total: 0,
      activeStep: 0,
      currentPage: 1,
      pageSize: 0,
      page: 1,
      newWorkflowModal: false,
      pdfPages: 1,
      authid: "",
      workflowName: "",
      modalLoading: false,
      allWorkflows: [],
      workflowId: null,
      workflowDataId: null,
      documentLogs: [],
      selected_workflow_instance: [],
      selectedRoute: "",
      documentModal: false,
      viewDetailedHistory: false,
      loadingText: "",
      loadingWorkflow: false,
      selectedDocuments: [],
      detailsDownload: false,
      selectedStatus: "",
      statusOption: [
        { name: "Draft", value: "DRAFT" },
        { name: "Sent", value: "SENT" },
        { name: "Completed", value: "COMPLETED" },
      ],
      selectedHistroyDocuments: {
        created_at: null,
        DocId: null,
        Docname: "",
        senderName: "",
        Doc_recipients: "",
        Document_Status: "",
        DocumentId: "",
        DateCreated: "",
        documentIdforHistory: "",
        checkDocumentStatus: false,
      },
    };
  },
  computed: {
    ...mapGetters("navigationOpen", [
      "getIsOpen",
      "getIsCollapse",
      "getIsMobile",
    ]),
    ...mapGetters("documents", [
      "getDocumentVoidStatus",
      "getResendDocumentStatus",
      "getDocumentCorrectStatus",
      "getDocumentCorrectErrors",
      "getCloneVoidDocStatus",
      "getCloneVoidDocData",
      "getDocumentLogs",
      "getSingleDocumentData",
      "getArchiveDocumentStatus",
    ]),
    ...mapGetters("auth", [
      "getAuthenticatedUser",
      "getActiveWorkspace",
      "getUserType",
    ]),
    ...mapGetters("templateWorkflow", [
      "getDeleteWorkflowStatus",
      "getAllWorkflows",
      "getWorkflowResendStatus",
      "getViewWorkflowData",
      "getDeleteArchivedWorkflowStatus",
      "getPermanentDeleteDraftDocStatus",
      "getWorkflowDataById",
      "getWorkflowDataCreatedData",
      "getWorkflowDataCreatedStatus",
    ]),
    ...mapGetters("settings", ["getApplicationSettings"]),
    paginationProps() {
      return {
        pageSizes: this.pageSizes || [10, 20, 50],
      };
    },
    basePdfdownloadUrl() {
      // return appConfig.JAVA_BASE_URL;
      return appConfig.S3_BUCKET_URL;
    },

    getSenderIpAddress() {
      let ip = "-";
      if (
        this.getSingleDocumentData &&
        this.getSingleDocumentData.data &&
        this.getSingleDocumentData.data.configurable_document_data &&
        this.getSingleDocumentData.data.configurable_document_data
          .sender_ip_address
      ) {
        ip = "";
        ip =
          this.getSingleDocumentData.data.configurable_document_data
            .sender_ip_address;
      } else {
        ip = "";
        ip =
          this.getSingleDocumentData &&
          this.getSingleDocumentData.data &&
          this.getSingleDocumentData.data.sender_ip_address;
      }
      return ip;
    },
    workflowdataName() {
      let names = this.getViewWorkflowData.data.find((el) => {
        if (el && el.name) {
          return el;
        }
      });
      return names.name;
    },
    documentIdforHistory() {
      let ids = [];

      if (
        this.getSingleDocumentData.data.document_generated_ids &&
        this.getSingleDocumentData.data.document_generated_ids.length
      ) {
        if (
          this.getSingleDocumentData.data.document_generated_ids.length === 1
        ) {
          ids = [this.getSingleDocumentData.data.document_generated_ids[0]];
        } else {
          ids = this.getSingleDocumentData.data.document_generated_ids.map(
            (id, index) => `${index + 1}) ${id}`
          );
        }
      }

      if (
        !ids.length &&
        this.getSingleDocumentData.data.document_generated_id
      ) {
        ids.push(this.getSingleDocumentData.data.document_generated_id);
      }

      if (!ids.length) {
        let doc_url =
          this.getSingleDocumentData.data.document_completed_urls &&
          this.getSingleDocumentData.data.document_completed_urls.length
            ? this.getSingleDocumentData.data.document_completed_urls
            : this.getSingleDocumentData.data.document_completed_url
            ? [this.getSingleDocumentData.data.document_completed_url]
            : [];
        if (doc_url.length) {
          doc_url.forEach((e) => {
            let data = e.split("/");
            ids.push(data[data.length - 1].replace(".pdf", "").split("_")[0]);
          });
        }
      }

      return ids.length ? ids.join("\n") : "";
    },
    getSenderEmail() {
      let sender = "";
      if (
        this.getSingleDocumentData &&
        this.getSingleDocumentData.data &&
        this.getSingleDocumentData.data.document_users
      ) {
        let tempSender = this.getSingleDocumentData.data.document_users.find(
          (el) => el && el.type && el.type == "SENDER"
        );
        sender = tempSender.email;
      }
      return sender;
    },
    getDocumentCompletedAt() {
      if (
        this.getSingleDocumentData &&
        this.getSingleDocumentData.data &&
        this.getSingleDocumentData.data.completed_at
      ) {
        return this.getSingleDocumentData.data.completed_at;
      }
      return "-";
    },
    getDocumentCreatedAt() {
      if (
        this.getSingleDocumentData &&
        this.getSingleDocumentData.data &&
        this.getSingleDocumentData.data.created_at
      ) {
        return this.getSingleDocumentData.data.created_at;
      }
      return "-";
    },
    getDocumentCreatedZone() {
      if (
        this.getSingleDocumentData &&
        this.getSingleDocumentData.data &&
        this.getSingleDocumentData.data.sender_time_zone
      ) {
        return this.getSingleDocumentData.data.sender_time_zone;
      }
      return "-";
    },
    getUsersForCertificate() {
      let users = [];
      if (
        this.getSingleDocumentData &&
        this.getSingleDocumentData.data &&
        this.getSingleDocumentData.data.document_users &&
        this.documentLogs &&
        this.getSingleDocumentData.data.configurable_document_data &&
        this.getSingleDocumentData.data.configurable_document_data.pages &&
        this.getSingleDocumentData.data.configurable_document_data.pages[0] &&
        this.getSingleDocumentData.data.configurable_document_data.pages[0]
          .fields
      ) {
        this.getSingleDocumentData.data.document_users.forEach((user) => {
          let obj = {};
          if (user.user_type != "CC") {
            if (user.sent_on) {
              obj.sent_time = user.sent_on;
            }
            if (user.document_filling_date) {
              obj.completed_time = user.document_filling_date;
            }
            obj.email = user.email;
            obj.name = user.first_name + " " + user.last_name;
            obj.print_complete =
              user && user.completed_by_print ? user.completed_by_print : false;
            if (
              user &&
              user.type == "SENDER" &&
              this.getSingleDocumentData.data.configurable_document_data
                .sender_ip_address
            ) {
              obj.user_ip =
                this.getSingleDocumentData.data.configurable_document_data.sender_ip_address;
            }
            this.documentLogs.forEach((el) => {
              if (
                !obj.user_ip &&
                user &&
                user.email &&
                el &&
                el.contact_id &&
                el.ip_address &&
                el.contact_id.email == user.email
              ) {
                obj.user_ip = el.ip_address;
              }
              if (
                !obj.accept_time &&
                user &&
                user.email &&
                el &&
                el.contact_id &&
                el.contact_id.email == user.email &&
                el.status == "ACCEPTED_TERMS_&_CONDITIONS"
              ) {
                obj.accept_time = el.time;
              }
              if (
                !obj.accept_time &&
                user &&
                user.email &&
                el &&
                el.contact_id &&
                el.contact_id.email == user.email &&
                el.status == "DOCUMENT_VIEWED"
              ) {
                obj.view_time = el.time;
              }
            });
            let elements =
              this.getSingleDocumentData.data.configurable_document_data
                .pages[0].fields;
            for (let index = 0; index < elements.length; index++) {
              let field = elements[index];
              const documentValue = this.getValueFromSavedDocument(field.key);
              if (
                (elements[index].type == "SIGNATURE" ||
                  elements[index].type == "INITIAL") &&
                documentValue
              ) {
                elements[index].source = documentValue;
                this.$set(elements[index], "source", documentValue);
              }
              if (
                elements[index].type == "PRIMARY_FIELDS" &&
                !elements[index].content
              ) {
                this.$set(elements[index], "content", documentValue);
              }
              if (documentValue) {
                this.$set(elements[index], "value", documentValue);
              }
            }
            elements.forEach((el) => {
              if (
                el &&
                (el.type == "SIGNATURE" || el.type == "my-signature") &&
                el.filled_by == user.value &&
                el.source
              ) {
                obj.signature_source = el.source;
              }
            });
            users.push(obj);
          }
        });
      }
      return users;
    },
    dateFormat() {
      let dateHistory = "";
      if (
        this.getApplicationSettings &&
        this.getApplicationSettings.data &&
        this.getApplicationSettings.data.date
      ) {
        dateHistory = this.getApplicationSettings.data.date;
        return dateHistory;
      } else {
        let date_hist = this.getDefaultDateFormat;
        return date_hist;
      }
    },
    getDocumentSubject() {
      let sub = "Requesting esign";
      if (
        this.getSingleDocumentData &&
        this.getSingleDocumentData.data &&
        this.getSingleDocumentData.data.email_content &&
        this.getSingleDocumentData.data.email_content.subject
      ) {
        sub = "";
        sub = this.getSingleDocumentData.data.email_content.subject;
      }
      return sub;
    },
    getInitialsCount() {
      let count = 0;
      if (
        this.getSingleDocumentData &&
        this.getSingleDocumentData.data &&
        this.getSingleDocumentData.data.configurable_document_data &&
        this.getSingleDocumentData.data.configurable_document_data.pages &&
        this.getSingleDocumentData.data.configurable_document_data.pages[0] &&
        this.getSingleDocumentData.data.configurable_document_data.pages[0]
          .fields
      ) {
        this.getSingleDocumentData.data.configurable_document_data.pages[0].fields.forEach(
          (el) => {
            if (el && (el.type == "INITIAL" || el.type == "MY_INITIAL")) {
              count = count + 1;
            }
          }
        );
      }
      return count;
    },
    checkDocumentStatus() {
      if (
        this.getSingleDocumentData &&
        this.getSingleDocumentData.data &&
        this.getSingleDocumentData.data.document_status &&
        this.getSingleDocumentData.data.document_status == "COMPLETED"
      ) {
        return true;
      }
      return false;
    },
    getPdfPages() {
      return this.pdfPages;
    },
    getSignaturesCount() {
      let count = 0;
      if (
        this.getSingleDocumentData &&
        this.getSingleDocumentData.data &&
        this.getSingleDocumentData.data.configurable_document_data &&
        this.getSingleDocumentData.data.configurable_document_data.pages &&
        this.getSingleDocumentData.data.configurable_document_data.pages[0] &&
        this.getSingleDocumentData.data.configurable_document_data.pages[0]
          .fields
      ) {
        this.getSingleDocumentData.data.configurable_document_data.pages[0].fields.forEach(
          (el) => {
            if (el && (el.type == "SIGNATURE" || el.type == "my-signature")) {
              count = count + 1;
            }
          }
        );
      }
      return count;
    },
    getMenuItemsStatus() {
      if (
        this.$route.query &&
        this.$route.query.routeType &&
        this.$route.query.t
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  async mounted() {
    this.authid =
      this.getAuthenticatedUser && this.getAuthenticatedUser._id
        ? this.getAuthenticatedUser._id
        : null;
    this.workflowId = this.$route.params.workflow_id;
    this.selectedRoute = this.$router.currentRoute.query.type;
    await this.fetchWorkflows();
  },
  methods: {
    getCurrentDraftStatus(data) {
      let status = "";
      if (
        data &&
        data.name &&
        data.workflow_users &&
        data.workflow_users.length == 0
      ) {
        status = `'${data.name}' has not been sent yet,and there are no configured user(s).`;
      } else if (
        data &&
        data.name &&
        data.workflow_users &&
        data.workflow_users.length > 0
      ) {
        status = `'${data.name}' has not been sent yet,and there are '${data.workflow_users.length}' configured users.`;
      } else {
        status = "-";
      }
      return status;
    },
    resetHistory() {
      this.viewDetailedHistory = false;
      this.selectedHistroyDocuments = [];
      this.selectedHistroyDocuments.created_at = null;
      this.selectedHistroyDocuments.DocId = null;
      this.selectedHistroyDocuments.Docname = "";
      this.selectedHistroyDocuments.senderName = "";
      this.selectedHistroyDocuments.Doc_recipients = "";
      this.selectedHistroyDocuments.Document_Status = "";
      this.selectedHistroyDocuments.DocumentId = "";
      this.selectedHistroyDocuments.DateCreated = "";
      this.selectedHistroyDocuments.documentIdforHistory = "";
      this.selectedHistroyDocuments.checkDocumentStatus = false;
    },
    getPrimaryUserFirstNameAndLastName(data) {
      let matchingWorkflow = this.getAllWorkflows?.data?.find(
        (workflow) =>
          workflow._id === data.workflow_id && data.workflow_users.length === 0
      );
      let name = "";
      let primaryUser = null;
      for (const user of data.workflow_users) {
        if (user.is_primary_user) {
          primaryUser = user;
          break;
        }
      }
      if (primaryUser) {
        const firstName = primaryUser.first_name;
        const lastName = primaryUser.last_name;
        name = firstName + " " + lastName;
        return name;
      } else if (
        data.primary_user &&
        data.primary_user.first_name &&
        data.primary_user.last_name
      ) {
        name = data.primary_user.first_name + " " + data.primary_user.last_name;
        return name;
      } else if (matchingWorkflow) {
        name =
          matchingWorkflow.created_by?.first_name +
          " " +
          matchingWorkflow.created_by?.last_name;
        return name;
      } else {
        name = "Users are not configured";
      }
    },
    async fetchWorkflowId() {
      let id = this.$route.params.workflow_id;
      await this.$store.dispatch("templateWorkflow/fetchWorkflowById", id);
      if (this.getWorkflowDataById) {
        this.setUserSettings(this.getWorkflowDataById.workflow_settings);
        this.workflowName = this.getWorkflowDataById.name;
        this.setSelectedTemplates(this.getWorkflowDataById.templates_used);
      } else {
        this.modalLoading = false;
      }
    },
    handleSelectionChange(val) {
      this.selected_workflow_instance = [];
      this.selected_workflow_instance = val;
    },
    async permanentDeleteWorkflowInstancesInDraft() {
      if (!this.checkPermissions("deleteWorkflow")) {
        this.$notify.error({
          title: "Error",
          message:
            "Permission denied to delete workflow. Please contact owner...!",
        });
      } else {
        this.selected_workflow_instance.forEach(async (e) => {
          let workflowId = e._id;
          if (
            e &&
            this.getAuthenticatedUser &&
            e.created_by == this.getAuthenticatedUser._id
          ) {
            this.configureLoading = true;
            this.LoadingText = "Deleting Workflow Instance...";
            await this.$store.dispatch(
              "templateWorkflow/PermanentDeleteDraft",
              {
                workflowId,
              }
            );
            if (this.getPermanentDeleteDraftDocStatus) {
              this.loading = false;
              // this.refreshDocument();
              this.successNotify(
                "Workflow Document Permanently Deleted Successfully"
              );
              await this.fetchWorkflows();
            }
          } else {
            this.$message({
              showClose: true,
              message:
                "You can only delete the workflow instances which are created by you.",
              type: "warning",
            });
          }
          await this.fetchWorkflows();
        });
      }
    },
    async onSend() {
      if (!this.checkPermissions("sendWorkflow")) {
        this.$notify.error({
          title: "Error",
          message:
            "Permission denied for sending  workflow. Please contact owner.!",
        });
      } else {
        let id = this.$route.params.workflow_id;
        await this.$store.dispatch("templateWorkflow/fetchWorkflowById", id);

        this.configureLoading = true;
        this.loadingText = "Sending workflow...";
        let params = {
          workflow_id: this.getWorkflowDataById._id,
          enforce_workflow_order: false,
          templates_used: this.getWorkflowDataById.templates_used,
          name: this.getWorkflowDataById.name,
          workflow_settings: this.getWorkflowDataById.workflow_settings,
          workflow_status: "DRAFT",
        };
        await this.$store.dispatch(
          "templateWorkflow/createNewWorkflowData",
          params
        );
        if (
          this.getWorkflowDataCreatedStatus &&
          this.getWorkflowDataCreatedData &&
          this.getWorkflowDataCreatedData._id
        ) {
          this.$notify({
            title: "Success",
            message: "Workflow data created successfully",
            type: "success",
          });
          this.configureLoading = false;
          let query = this.getNavigationQuery(this.$route.query);
          this.$router.push({
            name: "workflow-send",
            params: {
              workflow_data_id: this.getWorkflowDataCreatedData._id,
            },
            query: {
              ...query,
              activeStep: 1,
            },
          });
        } else {
          let id = this.$route.params.workflow_id;
          await this.$store.dispatch("templateWorkflow/fetchWorkflowById", id);

          this.configureLoading = true;
          this.loadingText = "Sending workflow...";
          let params = {
            workflow_id: this.getWorkflowDataById._id,
            enforce_workflow_order: false,
            templates_used: this.getWorkflowDataById.templates_used,
            name: this.getWorkflowDataById.name,
            workflow_settings: this.getWorkflowDataById.workflow_settings,
            workflow_status: "DRAFT",
          };
          await this.$store.dispatch(
            "templateWorkflow/createNewWorkflowData",
            params
          );
          if (
            this.getWorkflowDataCreatedStatus &&
            this.getWorkflowDataCreatedData &&
            this.getWorkflowDataCreatedData._id
          ) {
            this.$notify({
              title: "Success",
              message: "Workflow data created successfully",
              type: "success",
            });
            this.configureLoading = false;
            let query = this.getNavigationQuery(this.$route.query);
            this.$router.push({
              name: "workflow-send",
              params: {
                workflow_data_id: this.getWorkflowDataCreatedData._id,
              },
              query: {
                ...query,
                activeStep: 1,
              },
            });
          } else {
            this.$notify({
              title: "Error",
              message: "Error in sending workflow",
              type: "error",
            });
            this.configureLoading = false;
          }
        }
      }
    },
    async loadData(data) {
      if (data && data.type != "init") {
        this.pageSize = data.pageSize;
        this.page = data.page;
        await this.fetchWorkflows();
      }
    },
    expiray_date(doc) {
      let expiray_date =
        doc.document_settings &&
        doc.document_settings.expiration_settings &&
        doc.document_settings.expiration_settings.document_expired_date
          ? doc.document_settings.expiration_settings.document_expired_date
          : null;
      return expiray_date;
    },
    async getAllDocLogs(doc) {
      try {
        this.loadingText = "Loading History...";
        this.configureLoading = true;
        let document_id = doc._id;
        if (doc.document_status === "COMPLETED") {
          let lastPart = doc.document_completed_url.split("/");
          this.documentCompletedID = lastPart[lastPart.length - 1];
        }
        await this.$store.dispatch("documents/fetchDocumentDataById", {
          document_id,
        });
        await this.$store.dispatch("documents/fetchDocumentLogsById", {
          document_id,
        });
        if (this.getDocumentLogs && this.getDocumentLogs.data) {
          this.configureLoading = false;
          this.documentLogs = [];
          this.documentLogs = this.getDocumentLogs.data;
        }
        this.viewDetailedHistory = true;
        let senderName = doc.document_users.find((e) => {
          if (e && e.type == "SENDER") {
            return e;
          }
        });
        this.selectedHistroyDocuments.Docname = doc.title;
        this.selectedHistroyDocuments.senderName =
          senderName.first_name + " " + senderName.last_name;
        this.selectedHistroyDocuments.Document_Status = doc.document_status;
        this.selectedHistroyDocuments.created_at = doc.created_at;
        this.selectedHistroyDocuments.Doc_recipients = doc.document_users;
        this.selectedHistroyDocuments.documentIdforHistory =
          doc.document_generated_ids && doc.document_generated_ids.length
            ? doc.document_generated_ids.join(",")
            : doc.document_generated_id
            ? doc.document_generated_id
            : doc.document_completed_url &&
              doc.document_completed_url.slice(41, 77)
            ? doc.document_completed_url.slice(41, 77)
            : "-";
      } catch (err) {
        this.configureLoading = false;
        console.log("getAllDocLogs", err);
      }
    },
    async archiveDocument(doc) {
      try {
        let document_id = doc._id;
        this.$confirm("Are you sure to delete the document?")
          .then(async () => {
            this.documentModal = false;
            this.loadingText = "Deleting Document...";
            this.configureLoading = true;
            await this.$store.dispatch("documents/archiveDocument", {
              document_id,
            });
            this.configureLoading = false;
            if (this.getArchiveDocumentStatus) {
              this.configureLoading = false;
              successNotification("Document deleted successfully");
              //this.$emit("reloadDashboardStats", true);
              this.refreshDocument();
            } else {
              this.configureLoading = false;
              errorNotification("Error in deleting document");
            }
          })
          .catch(() => {});
      } catch (err) {
        this.configureLoading = false;
      }
    },
    refreshDocument() {
      window.location.reload();
    },
    generateReport() {
      this.detailsDownload = true;
      this.$refs.html2Pdf.generatePdf();
      this.detailsDownload = false;
    },
    async resendWorkflowToPrimaryUser(data) {
      try {
        this.loadingText = "Resending Workflow...";
        this.loadingWorkflow = true;
        let params = {
          workflow_id: data._id,
          name: data.name,
          created_by: data.created_by,
          workflow_settings: data.workflow_settings,
          primary_user: data.primary_user,
          workflow_data_id: data.workflow_id,
        };

        await this.$store.dispatch(
          "templateWorkflow/resendWorkflowToPrimaryUser",
          params
        );
        if (this.getWorkflowResendStatus) {
          this.loadingWorkflow = false;
          this.$notify({
            title: "Success",
            message: "Workflow resent successfully",
            type: "success",
          });
        }
      } catch (err) {
        console.log("resendWorkflowToPrimaryUser is Error", err);
      }
    },
    generateCertificate() {
      this.$refs.doc2Cer.generatePdf();
    },
    getValueFromSavedDocument(key) {
      if (
        this.getSingleDocumentData &&
        this.getSingleDocumentData.data &&
        this.getSingleDocumentData.data.document_data
      ) {
        return this.getSingleDocumentData.data.document_data[key];
      }
      return null;
    },
    async alertvoided(doc) {
      if (confirm) {
        this.$confirm(
          "Are you sure you want to Void the document?",
          "Warning",
          {
            confirmButtonText: "Yes",
            cancelButtonText: "No",
            type: "warning",
          }
        ).then(() => {
          this.DocumentVoid(doc);
        });
      }
    },
    async DocumentVoid(doc) {
      try {
        this.configureLoading = true;
        this.documentModal = false;
        this.loadingText = "Voiding Document...";
        let document_id = doc._id;
        await this.$store.dispatch("documents/voidDocument", { document_id });
        if (this.getDocumentVoidStatus) {
          this.configureLoading = false;
          this.$notify.success({
            title: "Success",
            message: "Document voided successfully",
          });
          this.$emit("reloadDashboardStats", true);
          this.refreshDocument();
        } else {
          this.configureLoading = false;
          this.$notify.error({
            title: "Error",
            message: "Error in void document",
          });
        }
      } catch (err) {
        this.configureLoading = false;
        console.log("DocumentVoid Error", err);
      }
    },
    async alertCorrectedDoc(doc) {
      if (!this.checkPermissions("editDraft")) {
        this.$notify.error({
          title: "Error",
          message:
            "Permission denied. Please contact owner for edit draft permission to clone document.",
        });
      } else {
        if (confirm) {
          this.$confirm(
            "Are you sure you want to correct the document?",
            "Warning",
            {
              confirmButtonText: "Yes",
              cancelButtonText: "No",
              type: "warning",
            }
          ).then(() => {
            this.correctDocument(doc);
          });
        }
      }
    },
    async EditVoidDocument(id) {
      if (!this.checkPermissions("editDraft")) {
        this.$notify.error({
          title: "Error",
          message:
            "Permission denied. Please contact owner for edit draft permission to clone document.",
        });
      } else {
        try {
          this.configureLoading = true;
          this.loadingText = "Cloning Document...";
          await this.$store.dispatch("documents/EditVoidDocument", id);
          if (this.getCloneVoidDocStatus && this.getCloneVoidDocData) {
            this.configureLoading = false;
            let new_id = this.getCloneVoidDocData.data._id;
            successNotification("Document cloned successfully");

            let query = this.getNavigationQuery(this.$route.query);
            this.$router.push({
              name: "employee-documents-custom-document-edit",
              params: {
                employee_document_id: new_id,
              },
              query,
            });
          } else {
            this.configureLoading = false;
            this.$notify({
              title: "Error",
              message: "Error in cloning document",
              type: "error",
            });
          }
        } catch (err) {
          this.configureLoading = false;
          console.log("EditVoidDocument", err);
        }
      }
    },
    async correctDocument(doc) {
      try {
        let document_id = doc._id;
        await this.$store.dispatch("documents/correctDocument", document_id);
        if (this.getDocumentCorrectStatus) {
          successNotification("Document is corrected successfully");

          let query = this.getNavigationQuery(this.$route.query);
          this.$router.push({
            name: "employee-documents-custom-document-edit",
            params: {
              employee_document_id: document_id,
            },
            query,
          });
        } else {
          let error_message = this.getDocumentCorrectErrors
            ? this.getDocumentCorrectErrors
            : "Error while correcting the document";
          this.$notify.error({
            title: "Error",
            message: error_message,
          });
          this.$emit("reloadDashboardStats", true);
          this.fetchWorkflows();
        }
      } catch (err) {
        console.log("correctDocument is error", err);
      }
    },
    checkPermissions(permission) {
      if (
        this.getUserType &&
        this.getUserType.name &&
        this.getUserType.name == "OWNER"
      ) {
        return true;
      }
      if (this.getUserType && this.getUserType.permissionSet) {
        return this.getUserType.permissionSet.includes(permission)
          ? true
          : false;
      } else {
        return true;
      }
    },
    isfilledOrNot(doc) {
      if (
        doc &&
        doc.document_status &&
        doc.configurable_document_data &&
        doc.configurable_document_data[0]
      ) {
        let fields = doc.configurable_document_data.pages[0].fields.filter(
          (el) => {
            if (
              el.filled_by &&
              el.filled_by != "SENDER" &&
              el.type != "my-signature" &&
              el.type != "MY_INITIAL"
            ) {
              return el;
            }
          }
        );
        let fields_data = doc.document_data;
        let filled_fields = fields.filter((el) => {
          return fields_data[el.key].length;
        });
        if (
          filled_fields.length &&
          doc.document_status != "DECLINED" &&
          doc.document_status != "COMPLETED" &&
          doc.document_status != "ARCHIVED"
        ) {
          return false;
        } else {
          return true;
        }
      } else {
        return false;
      }
    },
    setData() {
      this.documentName = this.getSingleDocumentData.data.title;
      this.document_url_path =
        this.getSingleDocumentData.data.configurable_document_data.document_upload_url;
      this.isDraft = this.getSingleDocumentData.data.document_status == "DRAFT";
      this.configurableDocumentId =
        this.getSingleDocumentData.data.configurable_document_id;
      this.documentId = this.getSingleDocumentData.data.company_document_id;
      this.workflowName = this.$route.query.workflow_name || "Company Document";
      this.documentCompletedID =
        this.getSingleDocumentData &&
        this.getSingleDocumentData.data &&
        this.getSingleDocumentData.data.document_completed_url
          ? this.getSingleDocumentData.data.document_completed_url
              .split("download/")
              .pop()
              .split(".pdf")[0]
          : "";
    },
    gotoMainDocument(data) {
      if (data && data.document_completed_url) {
        let url = this.basePdfdownloadUrl + data.document_completed_url;
        window.open(url, "_blank");
      }
    },
    fetchDocumentusers(doc) {
      let recipientUsers = [];
      if (
        doc &&
        doc.document_users &&
        doc.document_users.length &&
        doc.document_users.length > 1
      ) {
        doc.document_users.forEach((user) => {
          if (user && user.type != "SENDER" && !user.is_cc) {
            let obj = {};
            let name = user.first_name + " " + user.last_name;
            if (name && name.length) {
              obj.fullname = name;
              obj.email = user.email;
              recipientUsers.push(obj);
              return recipientUsers;
            } else {
              let users = "No Receivers";
              return users;
            }
          }
        });
        return recipientUsers;
      } else {
        let users = "SENDER";
        return users;
      }
    },

    async downloadPdfFile(url, name) {
      axios.defaults.headers.common = {
        "Access-Control-Allow-Origin": window.location.origin,
      };
      await fetch(url, { method: "get", responseType: "blob" })
        .then((res) => res.blob()) // Gets the response and returns it as a blob
        .then((blob) => {
          var fileURL = window.URL.createObjectURL(new Blob([blob]));
          var fileLink = document.createElement("a");
          let file_name = name.split(".")[0];
          fileLink.href = fileURL;
          fileLink.setAttribute("download", file_name + ".pdf");
          document.body.appendChild(fileLink);

          fileLink.click();
        });
    },
    getWaitingUser(doc) {
      if (doc.enforce_signature_order) {
        let currentUser = "";
        doc.document_users.forEach((user) => {
          if (
            (user.fields_required &&
              user.sent_status &&
              !user.document_filling_status) ||
            (user.sent_status &&
              user.has_approval_access &&
              !user.approval_status)
          ) {
            currentUser = user.first_name;
          }
        });
        let result = "Waiting for " + currentUser;
        return result;
      } else {
        let currentUser = "";
        doc.document_id.document_users.find((user) => {
          if (
            (user.fields_required &&
              user.sent_status &&
              !user.document_filling_status) ||
            (user.sent_status &&
              user.has_approval_access &&
              !user.approval_status)
          ) {
            currentUser += user.first_name + ",";
          }
        });
        if (currentUser.charAt(currentUser.length - 1) == ",") {
          currentUser = currentUser.slice(0, currentUser.length - 1);
        }
        let result = "Waiting for " + currentUser + " " + "Signatures";
        return result;
      }
    },
    onpermanentDocument(data) {
      if (!this.checkPermissions("deleteWorkflow")) {
        this.$notify.error({
          title: "Error",
          message:
            "Permission denied to delete workflow. Please contact owner...!",
        });
      } else {
        this.$confirm(
          `Are you sure you want to delete the workflow "${data.name}"`
        )
          .then(() => this.permanentDocument(data))
          .catch(() => {});
      }
    },
    async permanentDocument(data) {
      this.loading = true;
      this.loadingText = "Deleting workflow...";
      let workflowId = data._id;
      try {
        await this.$store.dispatch("templateWorkflow/PermanentDeleteDraft", {
          workflowId,
        });
        if (this.getPermanentDeleteDraftDocStatus) {
          this.loading = false;
          // this.refreshDocument();
          this.successNotify(
            "Workflow Document Permanently Deleted Successfully"
          );
          await this.fetchWorkflows();
        } else {
          this.loading = false;
          errorNotification("Error in Permanent document");
        }
      } catch (err) {
        this.loading = false;
        console.log("permanentDocument Error", err);
      }
    },

    onDeleteWorkflowConfirmation() {
      if (!this.checkPermissions("deleteWorkflow")) {
        this.$notify.error({
          title: "Error",
          message:
            "Permission denied. Please contact owner for deleting workflow.",
        });
      } else {
        this.$confirm(`Are you sure you want to delete the workflow`)
          .then(() => this.onDeleteWorkflow())
          .catch(() => {});
      }
    },
    async onDeleteWorkflow() {
      try {
        this.loadingText = "Deleting Workflow...";
        this.configureLoading = true;
        let currentActivateWorkflowId = this.selectedDocuments.find((e) => {
          if (
            e &&
            e.document_id &&
            e.document_id != null &&
            e.document_id.document_status == "SENT" &&
            e.document_id._id
          ) {
            return e.document_id._id;
          }
        });
        let workflow_id =
          currentActivateWorkflowId &&
          currentActivateWorkflowId.document_id &&
          currentActivateWorkflowId.document_id._id &&
          currentActivateWorkflowId.document_id._id
            ? currentActivateWorkflowId.document_id._id
            : null;
        await this.$store.dispatch(
          "templateWorkflow/DeleteWorkflow",
          workflow_id
        );
        if (this.getDeleteWorkflowStatus) {
          this.configureLoading = false;
          this.successNotify("Workflow deleted successfully");
        }
        this.documentModal = false;
        await this.fetchWorkflows();
        this.configureLoading = false;
      } catch (err) {
        this.configureLoading = false;
        console.log("OnDeleteWorkFlow", err);
      }
    },
    onDeleteConfirmation(template) {
      if (!this.checkPermissions("deleteWorkflow")) {
        this.$notify.error({
          title: "Error",
          message:
            "Permission denied. Please contact owner for deleting workflow.",
        });
      } else {
        let workflow_id = template._id;
        this.$confirm(`Are you sure to delete the workflow`)
          .then(() => this.onDelete(workflow_id))
          .catch(() => {});
      }
    },
    async onDelete(workflow_id) {
      try {
        this.loadingText = "Deleting Workflow...";
        this.loadingWorkflow = true;
        await this.$store.dispatch(
          "templateWorkflow/DeleteArchivedStatusWorkflow",
          workflow_id
        );
        if (this.getDeleteArchivedWorkflowStatus) {
          this.loadingWorkflow = false;
          this.successNotify("Workflow Archived successfully");
          await this.fetchWorkflows();
        } else {
          this.loadingWorkflow = false;
          this.errorNotify("Error in deleting Template");
        }
        this.loadingWorkflow = false;
      } catch (err) {
        this.loadingWorkflow = false;
        this.$message.error(
          "You can't perform delete action.Some documents are not in 'Archived Status'."
        );
      }
    },
    async fetchDocuments() {
      try {
        this.loadingText = "Fetching Documents...";
        this.configureLoading = true;
        let params = { limit: this.pageSize };
        await this.$store.dispatch("documents/fetchAllDocuments", params);
        this.documents = this.getAllDocuments.data;
        this.configureLoading = false;
      } catch (err) {
        this.configureLoading = false;
      }
    },
    async permanentDelete(data) {
      let document_id = data._id;
      try {
        this.$confirm(
          `Are you sure you want to permanently delete "${data.name}" document?`
        )
          .then(async () => {
            this.documentModal = false;
            this.loadingText = "Deleting Document...";
            this.configureLoading = true;
            await this.$store.dispatch("documents/parmanentdelete", {
              document_id,
            });
            this.configureLoading = false;
            if (this.getArchiveDocumentStatus) {
              this.configureLoading = false;
              this.successNotify(
                "Workflow Document Permanently Deleted Successfully"
              );
              this.refreshDocument();
            } else {
              this.configureLoading = false;
              errorNotification("Error in Permanent document");
            }
          })
          .catch(() => {});
      } catch (err) {
        this.configureLoading = false;
        console.log("permanentDelete", err);
      }
    },

    async resendDocLink(doc) {
      let sendinguser = doc.document_users.find((x) => {
        return (
          x.sent_status &&
          !x.document_filling_status &&
          !x.is_cc &&
          x.type != "SENDER"
        );
      });

      if (!sendinguser) {
        return;
      }

      try {
        let params = {
          document_id: doc._id,
          contact_id: sendinguser.contact_id
            ? sendinguser.contact_id
            : sendinguser.contact_id._id,
        };
        this.esignLoading = true;
        await this.$store.dispatch(
          "documents/resendDocumentLinkToContact",
          params
        );
        this.esignLoading = false;
        if (this.getResendDocumentStatus) {
          this.$notify.success({
            title: "Success",
            message: "Document link sent successfully",
          });
        }
      } catch (err) {
        this.esignLoading = false;
        console.log("resendDocLink", err);
      }
    },
    DocumentUsers(doc) {
      let users = "";
      let last_user = doc.document_users.length - 1;
      let isDocOwner = false;
      doc.document_users.forEach((user) => {
        if (
          user.email == this.getAuthenticatedUser.email &&
          user.type == "SENDER"
        ) {
          isDocOwner = true;
        }
      });
      if (isDocOwner) {
        users += "To: ";
        doc.document_users.forEach((user, i) => {
          if (user.type == "RECEIVER") {
            let sep = ",";
            if (i == last_user) {
              sep = " ";
            }
            if (!user.first_name) {
              users = "";
              users += "Users not assigned";
            } else {
              users += user.first_name + sep;
            }
          } else if (doc.document_users.length == 1) {
            users += "Only me";
          }
        });
      } else {
        users += "From: ";
        doc.document_users.forEach((user) => {
          if (user.type == "SENDER") {
            users += user.first_name;
          }
        });
      }
      return users;
    },
    downloadAlldocuments(data) {
      this.onDownload(data);
      let allAttachments = this.attachmentsFiles(data);
      if (allAttachments && allAttachments.length) {
        allAttachments.forEach((el) => {
          let url = this.basePdfdownloadUrl + el.attachment_url;
          this.downloadPdfFile(url, el.title);
        });
      }
    },
    async onDownload(doc, data) {
      if (!this.checkPermissionForDocument(doc, "downloadDocument")) {
        this.$notify.error({
          title: "Error",
          message:
            "Permission denied. Please contact owner for download document.",
        });
      } else {
        let url = data.document_completed_url
          ? data.document_completed_url
          : data.configurable_document_data.document_upload_url;

        await this.downloadFilesFromS3Paths([url], [data.name]);
      }
    },
    checkToOnlyResend(doc) {
      if (
        doc &&
        doc.document_users &&
        doc.document_users.length &&
        doc.document_users.length > 1
      ) {
        let user = doc.document_users.forEach((x) => {
          if (
            x.sent_status &&
            !x.document_filling_status &&
            !x.is_cc &&
            x.type != "SENDER"
          ) {
            if (user) {
              return true;
            } else {
              return false;
            }
          }
        });
        return true;
      } else {
        false;
      }
    },
    async sendWorkflow(data, step) {
      if (!this.checkPermissions("sendWorkflow")) {
        this.$notify.error({
          title: "Error",
          message:
            "Permission denied for sending workflow. Please contact owner.!",
        });
      } else {
        this.workflowDataId = data._id;

        let query = this.getNavigationQuery(this.$route.query);
        if (this.getMenuItemsStatus) {
          this.$router.push({
            name: "workflow-send",
            params: {
              workflow_data_id: data._id,
            },
            query: {
              ...query,
              activeStep: step,
              ...this.isMenuNavigation(),
            },
          });
        } else {
          if (
            data.workflow_users.length == 0 ||
            data.workflow_users.some((user) => !user.email || user.email == "")
          ) {
            this.$router.push({
              name: "workflow-send",
              params: {
                workflow_data_id: data._id,
              },
              query: {
                ...query,
                activeStep: 1,
              },
            });
          } else {
            this.$router.push({
              name: "workflow-send",
              params: {
                workflow_data_id: data._id,
              },
              query: {
                ...query,
                activeStep: 2,
              },
            });
          }
        }
      }
    },
    async openWorkflow(data) {
      this.selectedDocuments = [];
      this.selectedDocuments = data.documents.filter(
        (e) => e && e.document_id && e.document_id != null
      );
      this.documentModal = true;
    },
    format() {
      let doc = "";
      return doc;
    },
    getFormat(data) {
      let format = "";
      if (data && data.total_documents && data.total_documents != "ARCHIVED") {
        format = data.completed_documents + "/" + data.total_documents;
      } else {
        format = "0/0";
      }
      return format;
    },
    getWorkflowStatus(data) {
      let percentage = 0;
      if (data && data.completed_documents && data.total_documents) {
        percentage = (data.completed_documents / data.total_documents) * 100;
      }
      return percentage;
    },
    async sendWorkflowInViewWorkflowData(step) {
      let query = this.getNavigationQuery(this.$route.query);
      if (this.getMenuItemsStatus) {
        this.$router.push({
          name: "workflow-send",
          params: {
            workflow_data_id: this.$route.params.workflow_id,
          },
          query: {
            ...query,
            activeStep: step,
            ...this.isMenuNavigation(),
          },
        });
      } else {
        this.$router.push({
          name: "workflow-send",
          params: {
            workflow_data_id: this.$route.params.workflow_id,
          },
          query: {
            ...query,
            activeStep: step,
          },
        });
      }
    },
    async openNewWorkflow() {
      let query = this.getNavigationQuery(this.$route.query);
      if (this.getMenuItemsStatus) {
        this.$router.push({
          name: "workflow-edit",
          params: {
            workflow_id: this.workflowId,
          },
          query: {
            ...query,
            ...this.isMenuNavigation(),
          },
        });
      } else {
        this.$router.push({
          name: "workflow-edit",
          params: {
            workflow_id: this.workflowId,
          },
          query,
        });
      }
    },
    async goBack() {
      let query = this.getNavigationQuery(this.$route.query);
      this.$router.push({
        name: "Workflows",
        query,
      });
    },
    async onView(data) {
      let query = this.getNavigationQuery(this.$route.query);
      if (data && data.document_status == "DRAFT") {
        this.$router.push({
          name: "employee-documents-custom-document-edit",
          params: {
            employee_document_id: data._id,
          },
          query,
        });
      } else {
        let newWindow = open(
          `/emp-documents/custom/${data._id}`,
          "example",
          "width=1500,height=900"
        );
        newWindow.focus();
        newWindow.onload = function () {};
      }
    },
    async fetchWorkflows() {
      try {
        //  this.loadingText = "Fetching Workflows data...";
        this.configureLoading = true;
        let params = {
          limit: this.pageSize == 0 ? 10 : this.pageSize,
          page: this.page,
          id: this.workflowId,
        };
        if (this.selectedRoute) {
          params.selected_status = this.selectedRoute;
        }
        if (this.search_string) {
          params.search_string = this.search_string;
        }
        await this.$store.dispatch(
          "templateWorkflow/viewWorkflowByworkflowId",
          params
        );

        if (this.getViewWorkflowData) {
          this.allWorkflows =
            this.getViewWorkflowData && this.getViewWorkflowData.data
              ? this.getViewWorkflowData.data
              : [];
          this.total =
            this.getViewWorkflowData && this.getViewWorkflowData.total
              ? this.getViewWorkflowData.total
              : 0;
          this.configureLoading = false;
        } else {
          this.configureLoading = false;
        }
      } catch (err) {
        console.log("fetchWorkflows", err);
      }
    },
    isMenuNavigation() {
      if (this.getMenuItemsStatus) {
        return {
          routeType: this.$route.query.routeType,
          t: this.$route.query.t,
        };
      } else {
        return {};
      }
    },
  },
  watch: {
    search_string: function (val) {
      this.search_string = val;
      this.fetchWorkflows();
    },
    "$route.params.workflow_id"(workflowId) {
      this.authid =
        this.getAuthenticatedUser && this.getAuthenticatedUser._id
          ? this.getAuthenticatedUser._id
          : null;
      this.workflowId = workflowId;
      this.fetchWorkflows();
    },
    "$route.query.key"() {
      this.authid =
        this.getAuthenticatedUser && this.getAuthenticatedUser._id
          ? this.getAuthenticatedUser._id
          : null;

      this.workflowId = this.$route.params.workflow_id;
      this.fetchWorkflows();
    },
    selectedRoute: function (val) {
      this.selectedRoute = val;
      this.fetchWorkflows();
    },
  },
};
</script>
<style scoped lang="scss">
.document-box {
  padding: 10px;
  display: flex;
  border-top: 1px solid #d3d8db;
  border-right: 1px solid #d3d8db;
  border-left: 1px solid #d3d8db;
}
.document-box:last-child {
  border-bottom: 1px solid #d3d8db;
}
.doc-subs {
  margin-top: -13px;
  font-weight: 300;
  text-align: right;
  margin-right: 9%;
}
.doc-title {
  cursor: pointer;
  font-size: 14px;
  font-family: "Inter", sans-serif;
  font-weight: 300;
  line-height: 14px;
  text-align: left;
  color: #6c757d !important;
}
.activestatus {
  font-size: 14px;
  color: #6c757d !important;
}
.circle {
  position: relative;
  padding-left: 20px;
  text-transform: capitalize;
  font-size: 14px;
  &::after {
    background: #635e5e;
    content: "";
    position: absolute;
    top: 4px;
    left: 0;
    height: 10px;
    width: 10px;
    display: inline-block;
    border-radius: 50%;
  }
  &.completed::after {
    background: #08ad36;
  }
  &.draft::after {
    background: #f19203;
  }
  &.sent::after {
    background: #2f80ed;
  }
  &.active::after {
    background: #635e5e;
  }
  &.expired::after {
    background: #f03232;
  }
}
</style>
<style lang="scss">
.top-board {
  .title {
    display: flex;
  }
  .actions-wrapper {
    display: flex;
    justify-content: end;
    margin-top: 10px;
    .search {
      max-width: 170px;
    }
  }
}
.mobile {
  .workflows-table {
    tbody {
      tr.el-table__row {
        ul.action-buttons {
          li.el-tooltip {
            padding-left: 0px !important;
            padding-right: 0px !important;
          }
        }
      }
    }
  }
}
.workflows-table {
  tbody {
    tr.el-table__row {
      ul.action-buttons {
        li.el-tooltip {
          padding-left: 0px !important;
        }
      }
    }
  }
}
</style>
